import { ContentType, type PageViewPayload } from '@motortrend/nitrous-web-sdk';

import { ViewGroup } from '@/utils/nitrous/constants';

const getErrorPayload = (status: number): PageViewPayload | undefined => {
  try {
    return {
      content_type: ContentType.collection,
      view_group: ViewGroup.Error,
      view_name: `${status} Error`,
      view_template: 'error_page',
    };
  } catch (e) {
    console.error(e);
  }
};

export default getErrorPayload;
