'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { GenericClientErrorStrings as strings } from '@/translations';
import { mdiReload } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Typography } from '@motortrend/ids';
import { TrackPage } from '@motortrend/nitrous-web-sdk';

import hasWindow from '@/utils/hasWindow';
import { DataId } from '@/utils/nitrous/constants';
import getErrorPayload from '@/utils/nitrous/payloads/getErrorPayload';

/**
 * Nextjs will automatically render this component whenever an _unhandled_ error
 * is encountered on either the client or server. For component level error
 * handling, please wrap the component with `<ErrorBoundary fallback={<ErrorFallback />} />`
 *
 * @see https://nextjs.org/docs/app/building-your-application/routing/error-handling#how-errorjs-works
 */
export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset?: () => void;
}) {
  // Note that nextjs responds with a 500 when showing this page
  const payload = getErrorPayload(500);
  const router = useRouter();

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  const handleClick = () => {
    if (typeof reset === 'function') {
      reset();
    } else {
      if (hasWindow) {
        router.refresh();
      }
    }
  };

  return (
    <div className="grid place-items-center bg-neutral-7 pb-32 pt-28">
      <TrackPage payload={payload} />
      <Typography align="center" className="pb-2 lg:pb-4" variant="h3">
        {strings.Heading}
      </Typography>
      <Typography
        align="center"
        className="mb-12 w-11/12 !leading-6 md:w-4/12"
        variant="body2"
      >
        {strings.Message}
      </Typography>
      <Button
        colorScheme="neutral-3"
        data-id={DataId.Reload}
        data-parent={DataId.GenericErrorPage}
        onClick={handleClick}
        startIcon={<Icon path={mdiReload} size={1} />}
      >
        {strings.ActionLinkText}
      </Button>
    </div>
  );
}
